import api from '@/services/api';

const url = 'notice-board';

const getNoticeBoard = () => api.get(url);

const getNoticeUserSeen = (id) => api.get(`${url}/find-confirmation-by-notice/${id}`);

const createNoticeBoard = (noticeBoard) => api.post(url, noticeBoard);

const deleteNoticeBoard = (id) => api.delete(`${url}/${id}`);

const updateNoticeBoard = (noticeBoard) => api.put(url, noticeBoard);

export default {
  getNoticeBoard, getNoticeUserSeen, createNoticeBoard, deleteNoticeBoard, updateNoticeBoard,
};
