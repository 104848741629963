<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="noticeBoard"
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Mural de Avisos</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar "
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="showNoticeSeen(item)"
              v-on="on"
            >
              mdi-account-check
            </v-icon>
          </template>
          <span>Usuários que visualizou</span>
        </v-tooltip>
      </template>
      <template #item.active="{ item }">
        <v-checkbox
          :input-value="item.active"
          class="primary--text"
          value
          disabled
        />
      </template>
      <template #item.initialDate="{ item }">
        <p>{{ item.initialDate | parseDate }}</p>
      </template>
      <template #item.finalDate="{ item }">
        <p>{{ item.finalDate | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-noticeBoard
        v-if="dialog"
        :populate-with="noticeBoardToEdit"
        @update-notice-board-list="loadNoticeBoard"
        @close-dialog="closeDialog"
      />
    </v-dialog>
    <v-row justify="center">
      <v-dialog
        v-model="dialogNoticeSeen"
        fullscreen
        hide-overlay
      >
        <list-userSeen
          v-if="dialogNoticeSeen"
          :title="noticeUserSeenTitle"
          :data="noticeUserSeen"
          @close-dialog="closeDialog"
        />
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import NoticeBoard from '../../../services/notice-board.service';
// import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'NoticeBoard',
  components: {
    'form-noticeBoard': () => import('./Form'),
    'list-userSeen': () => import('./ListUserSeen'),
    // DeleteConfirmation,
  },
  data: () => ({
    headers: [
      {
        text: 'Ativo',
        align: 'start',
        sortable: false,
        value: 'active',
      },
      {
        text: 'Titulo',
        align: 'start',
        sortable: false,
        value: 'title',
      },
      {
        text: 'Subtítulo',
        align: 'start',
        sortable: false,
        value: 'subTitle',
      },
      { text: 'Data Inicial', value: 'initialDate', sortable: false },
      { text: 'Data Final', value: 'finalDate', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    noticeBoard: [],
    noticeUserSeen: [],
    noticeUserSeenTitle: '',
    search: '',
    dialog: false,
    dialogNoticeSeen: false,
    noticeBoardToEdit: {},
    dialogDeleteConfirmation: false,
    dialogDelete: true,
    deleteItemID: null,
  }),

  async created() {
    await this.loadNoticeBoard();
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.dialogNoticeSeen = false;
      this.noticeBoardToEdit = {};
      this.noticeuserSeen = [];
    },
    async loadNoticeBoard() {
      const data = await NoticeBoard.getNoticeBoard();
      this.noticeBoard = data;
    },

    editItem(noticeBoard) {
      this.noticeBoardToEdit = noticeBoard;
      this.dialog = true;
    },

    async showNoticeSeen(noticeBoard) {
      const dataNotice = await NoticeBoard.getNoticeUserSeen(noticeBoard.id);
      this.noticeUserSeenTitle = noticeBoard.title;
      this.noticeUserSeen = dataNotice;
      this.dialogNoticeSeen = true;
    },
  },
};
</script>
